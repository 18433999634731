

































































































































import { Vue, Component } from 'vue-property-decorator'
import ffButton from '@/components/public/Button.vue'
import { Collapse, CollapseItem, Icon, Toast } from 'vant'
import { addGA } from '../utils/index'
import { inGame } from '../utils'
import { namespace } from 'vuex-class'

const appStore = namespace('app')

type Replace<T> = (text: string) => T
type Func = (...args: any[]) => any
type PromiseFunc<T extends Func> = () => Promise<ReturnType<T>>
const delay = (delay = 2000) =>
  new Promise((resolve) => setTimeout(resolve, delay))
const cyclePromise = async <T extends Func>(
  callback: PromiseFunc<T>,
  count = 4
): Promise<ReturnType<T> | undefined> => {
  try {
    if (!count--) {
      return
    }
    // 终止条件
    const res = await callback()
    return res
  } catch {
    // 递归条件
    await delay()
    return cyclePromise(callback, count)
  }
}
const mockData = {
  activation_code: '',
}
const isDev = process.env['NODE_ENV'] == 'development'

const getMockData = () => Promise.resolve({ data: mockData })

@Component({
  components: {
    ffButton,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Icon.name]: Icon,
  },
})
export default class Download extends Vue {
  private activeName: Array<string> = ['0']
  @appStore.State((state) => state.transify) private transify: object
  @appStore.State((state) => state.downloadOpen) private downloadOpen: boolean
  @appStore.State((state) => state.activationCode)
  private activationCode: string
  @appStore.State((state) => state.feedbackOpen) private feedbackOpen: boolean
  @appStore.State((state) => state.apkLink) private apkLink: string
  @appStore.State((state) => state.playtestStartTime)
  private playtestStartTime: number
  @appStore.State((state) => state.gameLogin) private gameLogin: boolean
  @appStore.Action('updateHasDownload') private updateHasDownload!: Function
  @appStore.Action('setUserInfo') private setUserInfo!: Function
  private copyDialogShow: boolean = false
  private refreshPendding: boolean = true

  get FaqList(): Array<object> {
    const faqArr: Array<object> = []
    for (const key in this.transify) {
      if (key.indexOf('ADV_DOWNLOAD_FAQ_Q') > -1) {
        const num = key.charAt(key.length - 1)
        faqArr.push({
          title: this.transify[`ADV_DOWNLOAD_FAQ_Q${num}`],
          answer: this.transify[`ADV_DOWNLOAD_FAQ_A${num}`],
        })
      }
    }
    return faqArr
  }

  get DownloadTime(): string {
    const date = new Date(this.playtestStartTime * 1000)
    const [day, month, year] = [
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1,
      date.getFullYear(),
    ]
    return this.transify['ADV_DOWNLOAD_HINT_1']
      ? this.transify['ADV_DOWNLOAD_HINT_1']
          .replace('{day}', day.toString() + '.')
          .replace('{month}', month.toString() + '.')
          .replace('{year}', year.toString())
      : ''
  }

  get isInGame(): boolean {
    return inGame()
  }

  copyCode(): void {
    addGA('copy_activationCode', 'click')
    const tip = this.transify['ADV_COPY_SUCESS']
    this['$copyText'](this.activationCode).then(
      function(e: any) {
        Toast(tip)
      },
      function(e: any) {
        alert('Can not copy')
        console.log(e)
      }
    )
  }

  DownloadApk(): void {
    if (!this.downloadOpen) return
    if (inGame()) {
      // Toast(this.transify['ADV_APK_HINT'])
      // navigator.clipboard.writeText(this.apkLink)

      // 4月更新逻辑
      // const tip = this.transify['ADV_COPY_SUCESS']
      // this['$copyText'](this.apkLink).then(function (e: any) {
      //   Toast(tip)
      // }, function (e: any) {
      //   alert('Can not copy')
      //   console.log(e)
      // })
      this.copyDialogShow = true
      addGA('DownloadApk_ingame', 'click')
    } else {
      addGA('DownloadApk_outgame', 'click')
      window.location.href = this.apkLink // 跳转下载链接
    }
  }

  copyDialogHandle(): void {
    addGA('copy_apkLink', 'click')
    const tip = this.transify['ADV_COPY_SUCESS']
    this['$copyText'](this.apkLink).then(
      function(e: any) {
        Toast(tip)
      },
      function(e: any) {
        alert('Can not copy')
        console.log(e)
      }
    )
  }

  closeCopyDialog(): void {
    this.copyDialogShow = false
    addGA('closeCopyDialog', 'click')
  }

  handleFeedBack(): void {
    if (!this.gameLogin) {
      Toast(this.transify['ADV_DOWNLOAD_REPORTBUG_HINT'])
      return
    } else {
      this['$router'].push('/feedback').catch(() => {
        return
      })
    }
  }

  async userInfo(): Promise<void> {
    // const mockData = {
    //   is_ind: 0,
    //   registered: true,
    //   name: '謹慎的丹頂鶴306k',
    //   email: 'qinglong.shang.onsite@garena.com',
    //   tel: '',
    //   apk_url: 'garena',
    //   activation_code: '',
    //   feedback_questions: [],
    //   feedback_image_upload: true,
    // }
    // this.setUserInfo(mockData)

    const res = await this['$http'].get(`/info`)
    this.setUserInfo(res.data)
    addGA('get_info', 'success')
  }

  async refreshActivateCode() {
    Toast(this.transify['ADV_GENERATING'])
    return new Promise((resolve, reject) => {
      // Promise.resolve()
      //   .then(() => {
      //     if (isDev) {
      //       return getMockData()
      //     } else {
      //       return this['http'].post('/refresh')
      //     }
      //   })

      this['$http'].post('/refresh').then((res: { data: any }) => {
        const code = res.data.activation_code
        if (code) {
          resolve(code)
        }
        reject('')
      }, reject)
    })
  }

  async getActivateCode() {
    const fetchCode = await cyclePromise(this.refreshActivateCode)
    this.refreshPendding = false
    if (fetchCode) {
      this['$store'].commit('app/setValue', {
        key: 'activationCode',
        value: fetchCode,
      })
    }
    return fetchCode
  }

  async mounted() {
    await this.userInfo().finally(() => {
      if (!this.activationCode) {
        this.getActivateCode()
      } else {
        this.refreshPendding = false
      }
    })
  }
}
